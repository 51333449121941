window.vf = window.vf || {};
jQuery.ajaxSetup({cache: true });
(function () {
    'use strict';
    vf.config = {
        string: {
            show: 'show ',
            hide: 'hide ',
            cont: 'content ',
            drop: 'drop-list ',
            basket: 'items in your ',
            menu: 'menu ',
            error: 'Error',
            page: 'page ',
            'close-modal': 'Close<span class="access"> modal</span>',
            'view-more': 'View all',
            'tg-content': 'content',
            'multi-select-first': 'Select {label}',
            charcount: '{int} Zeichen \xfcbrig.',
            compareFree: ' - {number} out of {total} compare slots free.',
            compareAdd: 'Add to compare',
            compareNow: 'Compare Now',
            compareAdded: 'Added to compare',
            compareTooltipSingle: '1 device is added to compare.',
            compareTooltip: '{number} devices are added to compare.<br><a href="#" class="link-body">Go to detailed comparison.</a>',
            play: 'Play video'
        },
        view: {
            search: '<div class="search-cat"><div class="h4">{heading}</div>{items}</div>',
            // HEIKE
            // searchrow: '<li><a href="{targetUrl}" ><span class="icon icon-{icon}"></span><span>{name}</span></a></li>',
            searchrow: '<li><a href="{targetUrl}"><span class="icon"><img src="{ImageUrl}"></span><span>{name}</span></a></li>',
            searchresults: '<div class="se-results scrollBox scroll-pane"></div>'
        },
        flyoutJs: {
            config: '/simplicity/config/flyout.config.js',
            flyout: '/simplicity/assets/js/detached/new.flyout.js',
            css: '/styles/toolbar-flyout-sd4.css'
        },
        areas: {
            maps: {
                hierarchy: {
                    searchCustomerContext: {
                        'c2583998-4bab-4f28-886c-f9b6f20291a0': 'private',
                        '0b337cd2-f30e-4f23-84ad-a7769166c51a': 'soho',
                        '1ab70c7c-43fb-4173-9ac6-a35bc99e2230': 'soho',
                        '63483e43-7b35-4bbf-a992-7f5dc523dd2d': 'enterprise',
                        'd6d74896-101a-4ac2-b109-7fbfb36bb141': 'enterprise'
                    },
                    searchContext: {
                        'cd301fc2-88bd-4892-a943-1c4054c2573c': 'domain.support', //Hilfe->....
                        '3c5e7095-8b99-4c80-9357-96237b0f9ba6': 'domain.shop', //Mobilfunk->...
                        'c7b0e395-a158-40e0-ae83-212041396047': 'domain.allg-info' //MeinVodafone->...

                    }
                }
            }
        },
        searchbox: {
            hierarchy: (0 !== $('meta[name="hierarchy"]').length && 0 < $('meta[name="hierarchy"]').attr('content').length ? $('meta[name="hierarchy"]').attr('content').split('|') : []),
            facets: (0 !== $('meta[name="facets"]').length && 0 < $('meta[name="facets"]').attr('content').length ? $('meta[name="facets"]').attr('content').split('|') : []),
            path: document.location.pathname.replace('/', '').split('/'),
            searchTarget: {
                'private': '/privat/suche.html',
                'soho': '/business/suche.html',
                'enterprise': '/unternehmen/suche.html'
            }
        },
        form: {
            html: {
                eheading: 'Sorry there have been {{count}} errors:',
                error: '<div class="alert warning fm-error"><svg class="icon-warning-i-sml i-sml"><use xlink:href="/simplicity/svgdefs.svg#i-warning-sml" /></svg><div><h4><span class="access">Error: </span>{{message}}.</h4></div></div>',
                required: 'Bitte f\xfcllen Sie das Feld aus'
            },
            patterns: {
                alpha: {
                    match: /^([A-Za-z-\s]+)$/,
                    error: 'Please ensure only text is entered'
                },
                numeric: {
                    match: /^\d+$/,
                    error: 'Please ensure only numbers are entered'
                },
                alphanumeric: {
                    match: /^([A-Za-z0-9]+)$/,
                    error: 'Please ensure only text and/or numbers are entered'
                },
                postcode: {
                    match: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/,
                    error: 'Please enter a valid UK postcode (e.g. AA1 2BB)'
                },
                date: {
                    match: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]((19|20)\d\d)$/,
                    error: 'Please enter a valid date (e.g. 28/11/2013)'
                },
                landline: {
                    match: /^([\+(][+0-9()]{1,5}([ )\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9 \-]{1,256}([ \s\-])?)((x|ext|extension)?[0-9]{1,4}?)$/i,
                    error: 'Please enter a valid landline number (e.g. 020 1234 1234)'
                },
                mobile: {
                    match: /^((0|\+44|00\d{2})7(5|6|7|8|9){1}\d{2}\s?\d{6})$/,
                    error: 'Please enter a valid mobile number (e.g. 07555 000000)'
                },
                email: {
                    match: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
                    error: 'Please enter a valid email address (e.g. johndoe@gmail.com)'
                },
                pswmatch: {
                    error: 'The passwords don\'t match'
                },
                onerequired: {
                    none: 'One of these fields has to be filled',
                    more: 'Just one of these fields has to be filled'
                },
                min20: {
                    match: /.{20,}$/,
                    error: 'Sie m\xfcssen mehr als 20 Zeichen eingeben'
                },
                min1: {
                    match: /.{1,}$/,
                    error: 'Sie m\xfcssen mindestens als 2 Zeichen eingeben'
                },
                min10: {
                    match: /.{10,}$/,
                    error: 'Bitte geben Sie mindestens als 10 Zeichen ein'
                }
            },
            functions: {
                checkUser: {
                    rule: function () {
                        var e = !0,
                            r = this;
                        return r.validate() && $.ajax({
                            type: 'POST',
                            url: '../../assets/json/response.json',
                            dataType: 'json',
                            async: !1,
                            success: function (d) {
                                e = d[0].username;
                            }
                        }), 'false' !== e;
                    },
                    error: 'This username is not valid'
                },
                customCombine: {
                    rule: function () {
                        var e = this,
                            r = e.data('combine') ? !0 : !1,
                            a = $(r ? e.data('combine') : e.data('required')),
                            s = !0;
                        return r ? '' !== a.val() && a.validate() && '' === e.val() ? (e.find(vf.form.settings.error).add(e.prev(vf.form.settings.error)).add(e.closest(vf.form.settings.data).prev(vf.form.settings.error)).remove(), vf.form.error.apply(e, [vf.config.form.functions.customCombine.error])) : (a.find(vf.form.settings.errorborder).addBack(vf.form.settings.errorborder).removeClass(vf.form.settings.errorborder.substring(1)), a.find(vf.form.settings.error).add(a.prev(vf.form.settings.error)).add(a.closest(vf.form.settings.data).prev(vf.form.settings.error)).remove()) : '' !== e.val() && e.validate() && '' === a.val() ? (a.find(vf.form.settings.error).add(a.prev(vf.form.settings.error)).add(a.closest(vf.form.settings.data).prev(vf.form.settings.error)).remove(), vf.form.error.apply(a, [vf.config.form.functions.customCombine.error])) : (a.find(vf.form.settings.errorborder).addBack(vf.form.settings.errorborder).removeClass(vf.form.settings.errorborder.substring(1)), a.find(vf.form.settings.error).add(a.prev(vf.form.settings.error)).add(a.closest(vf.form.settings.data).prev(vf.form.settings.error)).remove()), s;
                    },
                    error: 'This field is required'
                }
            }
        },
        worldmanual: {
            troubleshooting: {
                startStrg: '<h4>Start the troubleshooting guide.</h4><p>The troubleshooting guide will walk you through a number of possible causes and solutions</p>',
                Start: 'Start',
                yes: 'Ja',
                no: 'Nein',
                resolvedUrl: '../../support/assets/world-manual/Troubleshooting/GetDeviceTroubleshootingSolvedText.xml',
                unresolvedUrl: '../../support/assets/world-manual/Troubleshooting/GetDeviceTroubleshootingNotSolvedText.xml'
            },
            articles: {
                noImage: 'There is no image for this step. Select the arrow button to continue.'
            }
        },
        ajaxLoad: '<div class="tg-progress"><div><img src="../../assets/img/loader-lrg.gif" alt="Loading content" aria-busy="true"><div>Loading...<span class="access"> content</span></div>'
    };
})(vf);
(function () {
    'use strict';
    $(function () {
    }),
    function (e) {
        var r = {
            settings: {
                required: '.fm-required',
                checked: ':checked, .checkbox',
                pattern: '[data-pattern]',
                pswmatch: '.fm-pswmatch',
                onerequired: '.fm-onerequired'
            },
            match: function () {
                var r = this,
                    a = !0;
                return r.find('input').addBack('input').each(function () {
                    var r = e(this),
                        s = vf.config.form.patterns[r.data('pattern')],
                        t = r.val().match(s.match);
                    t || (a = !1);
                }), a;
            },
            matchPassword: function (v) {
                var a = e(v.data('match')),
                    s = v.val();
                return a.val() !== s ? !1 : !0;
            }
        };
        e.fn.validate = function () {
            var a = e(this),
                s = r.settings,
                t = s.required + ',' + s.pattern + ',' + s.pswmatch + ',' + s.onerequired,
                o = !0;
            return e.uniqueSort(a.find(t).addBack(t)).each(function () {
                var a = e(this);
                a.has('[type=radio]')[0] ? a.has(s.checked)[0] || (o = !1) : (a.is(s.required) ? a.has(':unfilled')[0] || a.is(':unfilled') || !a.is(s.checked) && a.is('[type=checkbox]') ? o = !1 : (a.has(s.pattern)[0] || a.is(s.pattern)) && (o = e.proxy(r.match, a)()) : (a.has(':unfilled')[0] || 0 === a.is(':unfilled')) && (o = e.proxy(r.match, a)()), a.is(s.pswmatch) && (o = r.matchPassword(a)));
            }), o;
        };
    }(jQuery);

    // FLYOUT STUFF
    window.getGlobalToolbar = function () {
        window.setTimeout(VF.runToolbar, 300);
    };
    VF.isAsalesArea = function () {
        var myfileName = document.location.pathname.substr(document.location.pathname.lastIndexOf('/') + 1, ((document.location.pathname.lastIndexOf('/') + 1) - (document.location.pathname.lastIndexOf('.'))) * -1),
            pathArr = document.location.pathname.substr(1, document.location.pathname.lastIndexOf('/') - 1).split('/');
        if ((-1 !== document.location.href.indexOf('shop.')) || -1 !== $.inArray(myfileName, VF.wwwFileSpecialsSaleFixed) || VF.thereAreArrMatches(pathArr, VF.wwwPathSpecialsSaleFixed) || (-1 !== $.inArray(myfileName, VF.wwwFileSpecialsSale) || VF.thereAreArrMatches(pathArr, VF.wwwPathSpecialsSale)) && (-1 === myfileName.indexOf('prepaid-ohne-vertrag'))) {
            return true;
        }
        return false;
    };
    VF.setSessionStorage = function (key, value) {
        if (!value) {
            window.sessionStorage.removeItem(key); return true;
        }
        try {
            window.sessionStorage.setItem(key, value);
            return window.sessionStorage.getItem(key);
        } catch (e) {
            return false;
        }
    };
    VF.getSessionStorage = function (key) {
        try {
            return window.sessionStorage.getItem(key);
        } catch (e) {
            return false;
        }
    };
    VF.createScript = function (src, id) {
        var s = document.createElement('script');
        if ('undefined' !== typeof id) {
            s.id = id;
        }
        s.src = src;
        (document.getElementsByTagName('head')[0] || document.body).appendChild(s);
    };
    VF.ToolbarUnwishedArea = function () {
        var unwishedArr = ['meinvodafone','ussa', 'proxy42', 'hilfe', 'selbstaendige', 'business', 'enterprise', 'firmenkunden'],
            currPath = document.location.href;
        if ((-1 !== document.location.search.indexOf('TealiumTrackingEnabled=false'))) {
            return true;
        }
        for (var i = 0; i < unwishedArr.length; i++) {
            if (-1 !== currPath.indexOf(unwishedArr[i])) { return true; }
        }

        return false;
    };
    VF.runToolbar = function () {
        VF.toolBarEnabled = (!VF.ToolbarUnwishedArea());
        if (VF.toolBarEnabled && (!navigator.userAgent.toLowerCase().match(/(msie 6.0)/))) {
            $.ajax({
                'url': vf.config.flyoutJs.css,
                'dataType': 'text'
            }).done(function (d) {
                $('head').append('<style type="text/css"  >' + d + '</style>');
                $.getScript(vf.config.flyoutJs.config, function() {
                    VF.createScript(vf.config.flyoutJs.flyout);
                });
            });
            // window.setTimeout('initChats()', 500);
        }

    };

    VF.forceRunToolbar = function () {
        if ((!navigator.userAgent.toLowerCase().match(/(msie 6.0)/))) {
            $.ajax({
                'url': vf.config.flyoutJs.css,
                'dataType': 'text'
            }).done(function (d) {
                $('head').append('<style type="text/css"  >' + d + '</style>');
                $.getScript(vf.config.flyoutJs.config, function() {
                    VF.createScript(vf.config.flyoutJs.flyout);
                });
            });
        }

    };

    /*var isNotCheckout = function () { Leer
        'use strict';
        if (-1 !== document.location.href.indexOf('/picknmix/precheckout/') || -1 !== document.location.href.indexOf('/submitOrder/newSubmitOrder.jsp') || -1 !== document.location.href.indexOf('/checkout/') || -1 !== document.location.href.indexOf('/micropages/') || -1 !== document.location.href.indexOf('/Shop/submitOrder/') || -1 !== document.location.href.indexOf('/Shop/reviewOrder/') || -1 !== document.location.href.indexOf('/picknmix/write_review')) {
            return false;
        }
        if (!window.s) {
            return true;
        } else {
            try {
                return (((-1 === s.events.indexOf('ScCheckout')) && (-1 === s.events.indexOf('event23')) && (-1 === s.events.indexOf('event24'))));
            } catch (e) {
                return false;
            }
        }
    };*/
    $(function () {
        if ('undefined' === typeof VF.toolBarEnabled) {
            VF.toolBarEnabled = true;
        }
        if (VF.toolBarEnabled) {
            VF.runToolbar();
        }
    });

    if ('undefined' === typeof $.fn.exists) {
        $.fn.exists = function (selector) {
            return 0 < $(selector).length;
        };
    }

    jQuery.cookie = function (name, value, options) {
        var cookieValue = null,
            cookies,
            cookie,
            i,
            date,
            path,
            domain,
            secure,
            expires = '';
        options = options || {};
        if ('undefined' === typeof options.noEncode) {
            options.noEncode = false;
        }
        if ('undefined' !== typeof value) {
            if (null === value) {
                value = '';
                options.expires = -1;
            }
            if (options.expires && ('number' === typeof options.expires || options.expires.toUTCString)) {
                if ('number' === typeof options.expires) {
                    date = new Date();
                    date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1E3);
                } else {
                    date = options.expires;
                }
                expires = ';expires=' + date.toUTCString();
            }
            path = options.path ? ';path=' + options.path : '';
            domain = options.domain ? ';domain=' + options.domain : '';
            secure = options.secure ? ';secure' : '';
            document.cookie = [name, '=', true === options.noEncode ? value : encodeURIComponent(value), expires, path, domain, secure].join('');
        } else {
            if (document.cookie && '' !== document.cookie) {
                cookies = document.cookie.split(';');
                for (i = 0; i < cookies.length; i++) {
                    cookie = jQuery.trim(cookies[i]);
                    if (cookie.substring(0, name.length + 1) === name + '=') {
                        try {
                            cookieValue = true === options.noEncode ? cookie.substring(name.length + 1) : decodeURIComponent(cookie.substring(name.length + 1));
                        } catch (e) {
                            cookieValue = true === options.noEncode ? cookie.substring(name.length + 1) : unescape(cookie.substring(name.length + 1));
                        }
                        break;
                    }
                }
            }
            return cookieValue;
        }
        return null;
    };
    jQuery.cookieASCII = function (name, value, options) {
        options = options || {};
        if ('undefined' !== typeof value) {
            if (null === value) {
                value = '';
                options.expires = -1;
            }
        }
        options.noEncode = true;
        return $.cookie(name, value, options);
    };

    (function () {
        vf.search = {
            settings: {
                target: '.mod-search',
                results: '.se-results',
                input: '.se-input',
                serviceUrl: '/api/search/SearchBridge/search?sv=suggest&wt=json',
                mappings: {
                    searchkeys: ['forum', 'support', 'allg-info', 'shop'],
                    values: ['forum', 'hilfe', 'info', 'shop']
                }
            },
            statusActive: false,
            init: function (a) {
                var b = vf.search.settings;
                $(b.target, a).addBack(b.target).each(function () {
                    $(this).append(vf.config.view.searchresults);
                    $(vf.search.settings.results).hide();
                    $(this).find('input' + vf.search.settings.input).attr('name', 'q').attr('accept-charset', 'UTF-8');
                    $(this).find('input').on('keypress', function (e) {
                        if (13 === e.keyCode) {
                            vf.search.dismissSearchResults(e); window.cancelSuggest = true;
                            VF.setSessionStorage( 'resultsCount' );
                            VF.setSessionStorage( 'selectedAreaFacetValues' );
                        } else {
                            window.cancelSuggest = false;
                        }
                    });
                    if ($(this).hasClass('search')) {
                        $(this).find('form').remove('.iButton').add('<input type="reset" value="" class="iReset"> <input type="submit" value="" class="iSubmit">');
                    }
                    if (0 === $(this).find('button[type="reset"]').length) {
                        $(this).find('button.btn-alt').remove();
                        $(this).find('.se-query').append('<button class="btn" type="reset"></button><button class="btn btn-em" type="submit"></button>');
                    }
                    $(this).find('button[type="reset"]').on('click', function () {
                        $(this).parent().parent('form')[0].reset();
                        $('.se-query.search-all-button').hide();
                    });
                    $(this).find('input[type="text"]').on('keyup', function(){$(this).val($(this).val().replace( /<|>|\(|\)|;/g, '' ));});
                    if ( !$(this).find('input[type="text"]').hasClass('search-string') ){
                        $(this).find('form').attr('accept-charset', 'UTF-8').on('submit', function (e) {
                            if (2 > $(this).find('input[type="text"]').val().length) {
                                return false;
                            }
                            var tVal = window.encodeURIComponent($(this).find('input[type="text"]').val()),
                                //contextVal = $(this).find('input[name="searchCustomerContext"]').val();
                                contextVal = $(this).find('input[name="searchCustomerContext"]').val().replace( '.shop', '.allg-info' );// placed Workaround  for INC-10287 just to remove with next drop.
                            VF.setSessionStorage('searchTerms', $(this).find('input[type="text"]').val());
                            document.location.href = $(this).attr('action') + '?searchCustomerContext=' + contextVal + '&q=' + tVal;

                            return false;
                        });
                    }
                    $(this).find('input').on('focus', function (e) {
                        if ($(this).hasClass('search-string')) {
                            $(this).parent().parent().addClass('on-focus');
                        } else {
                            $(this).parent().addClass('on-focus');
                        }
                    });
                    $(this).find('input').on('focusout',
                        function (a) {
                            var b = $(this).parent(); $(this).hasClass('search-string') && (b = $(this).parent().parent()),
                            setTimeout(function (a) { $(a).removeClass('on-focus'); }, 300, b);
                        });
                    $(this).find('input').on('keyup', $.proxy(vf.search.complete, $(this)));
                    $(this).find('input').on('click', $.proxy(vf.search.complete, $(this)));
                    vf.search.removeNotNeededFields($(this).find('form'));
                    vf.search.updateSearchBoxContextAndTarget($(this).find('form'));
                });
                vf.search.statusActive = true;


                $(document).on('click', vf.search.dismissSearchResults);
            },
            view: function (a) {
                var b = '';
                var getClassNameKey = function (str) {
                    for (var i = 0; i < vf.search.settings.mappings.searchkeys.length; i++) {
                        if (str === vf.search.settings.mappings.searchkeys[i]) {
                            return vf.search.settings.mappings.values[i];
                        }
                    }
                    return 'hilfe';
                };
                var results = {};
                $.each(a, function () {
                    results[this.Domain_facet] = results[this.Domain_facet] || [];
                    results[this.Domain_facet].push(this);
                    //$('.se-query.search-all-button').show();

                    //debugger;


                });
                $.each(results, function (key, val) {
                    if ((!vf.search.settings.filter) || vf.search.settings.filter && -1 !== $.inArray(val[0].Domain_facet, vf.search.settings.filter)) {
                        var e = '',
                            a = vf.config.view.search;
                        a = a.replace(/{heading}/g, val[0].ecs_group);
                        $.each(val, function () {
                            //var linkUrl = -1 == this.URL.indexOf('//') ? '//' + VF.shopHost + this.URL : this.URL;

                            // if exist no image path display default icon
                            if (!this.Image.includes('https://eweb.vfd2-testnet.de') && this.Image) {
                                this.Image = 'https://eweb.vfd2-testnet.de/images/icons/Search_Icons/info-circle-mid.svg';
                            }

                            var a = vf.config.view.searchrow,
                                // HEIKE
                                // a = a.replace(/{icon}/g, getClassNameKey(this.Domain_facet)),
                                // https://eweb.vfd2-testnet.de/images/icons/Search_Icons/shopping-trolley-hi.svg
                                a = a.replace(/{ImageUrl}/g, this.Image),
                                a = a.replace(/{name}/g, this.ecs_name),
                                a = a.replace(/{targetUrl}/g, this.URL);
                            e += a;
                        });
                        e = '<ul>' + e + '</ul>';
                        a = a.replace(/{items}/g, e);
                        b += a;
                    }
                });
                return b;
            },
            complete: function (e) {
                var a = vf.search.settings,
                    b = $(this).find(a.input),
                    c = $(this).find(a.results);
                window.activeSearchForm = $(this);
                if (window.cancelSuggest) { return true; }
                var getSelectedCustomerContextValue = function () {
                    var urlParams = new URLSearchParams(location.search);
                    var sCustomerContext = urlParams.get('searchCustomerContext');
                    if (!sCustomerContext){
                        try {
                            return $(window.activeSearchForm).find('input[name="searchCustomerContext"]').val().split('|')[0];
                        } catch (e) {
                            return (VF.getSessionStorage('selectedCustomerContextFacetValue') ? VF.getSessionStorage('selectedCustomerContextFacetValue') : 'private');
                        }
                    } else {
                        /**
                         * https://jira.wf-de.vodafone.com/browse/INC-31021
                         * sCustomerContext muss encodewd werden, weil er ein Pipe-Zeichen beinhaltet
                         */
                        return encodeURIComponent(sCustomerContext);
                    }

                };

                '' === b.val() ? vf.search.close(c) : 2 > b.val().length || $.ajax({
                    url: vf.search.settings.serviceUrl + '&Filter=' + 'customertype:' + getSelectedCustomerContextValue(), //$(this).data('json'),
                    data: $(this).find('form').serialize(),
                    success: function (dta) {
                        var a = [];
                        try {
                            a = JSON.parse(dta);
                        } catch (e) {
                            a = dta;
                        }
                        if ($(b).parent().hasClass('se-help')) {
                            vf.search.settings.filter = ['support'];
                        } else {
                            vf.search.settings.filter = null;
                        }
                        $(c).html(vf.search.view(a));
                        vf.search.trackResult(a, b.val());
                        vf.search.open(c);
                        vf.setupSearchboxTracking(a, b.val());
                        //
                        $('.se-query.search-all-button').show();
                        // $('.scroll-pane').css('max-height', ($(window).height() - ($('.scroll-pane').position().top) - 20) + 'px').jScrollPane();
                    }
                });
            },
            trackResult: function (data, q) {
                if (0 < data.length) {
                    VF.setSessionStorage('searchType', 'autosuggest');
                    VF.setSessionStorage('searchTerms', q);
                    VF.setSessionStorage('searchNumberOfResults', data.length);
                }
            },
            open: function (a) {
                window.activeSearchForm.find(a).show();
                //$(a).show();
                $('body').on('click', function () {
                    if ($('.se-results:visible')) {
                        $('.se-results').hide();
                    }
                });
                $('#content').css('min-height', $(a).height() - $('div.footer').height() + 20 + 'px');
            },
            close: function (a) {
                $(a).hide();
            },
            dismissSearchResults: function (e) {
                var s = vf.search.settings;
                if (!$(e.target).is('input', s.target) && !$(e.target).closest(s.target).length) {
                    $(s.results).empty().hide();
                    $('.se-query.search-all-button').hide();

                }
            },
            updateSearchBoxContextAndTarget: function (form) {
                var boxHandling = (0 < vf.config.searchbox.facets.length ? 'facet' : (0 < vf.config.searchbox.hierarchy.length ? 'hierarchy' : 'path'));
                var getResolvedByPath = function () {
                    var businessArr = ['firmenkunden', 'selbstaendige', 'business'], enterpriseStr = 'firmenkunden',
                        uri = document.location.href;
                    for (var i = 0; i < businessArr.length; i++) {
                        if (-1 !== uri.indexOf(businessArr[i])) {
                            return (enterpriseStr === businessArr[i] ? 'enterprise' : 'soho');
                        }
                    }
                    return 'private';

                };
                var getFacetObject = function () {
                    var obj = {};
                    $.each(vf.config.searchbox.facets, function (i) {
                        if (0 < vf.config.searchbox.facets[i].length) {
                            obj[vf.config.searchbox.facets[i].split(':')[0]] = vf.config.searchbox.facets[i].split(':')[1];
                        }
                    });
                    if ('undefined' === typeof obj.businesstype) {
                        obj.businesstype = '';
                    }
                    if ('undefined' === typeof obj.domain) {
                        obj.domain = 'allg-info';
                    }
                    return obj;
                };
                var facetsStr = '';
                $('input[name="searchCustomerContext"]').val('private');
                $('input[name="searchContext"]').val('domain.allg-info');

                $(form).attr('action', vf.config.searchbox.searchTarget.private).attr('method', 'GET');
                switch (boxHandling) {
                case 'facet':
                    var facets = getFacetObject();

                    switch (facets.customertype) {
                    case 'private':
                        $(form).attr('action', vf.config.searchbox.searchTarget[facets.customertype]).attr('method', 'GET');
                        $('input[name="searchCustomerContext"]').val('private');
                        break;
                    case 'business':
                    case 'enterprise':
                        $('input[name="searchCustomerContext"]').val('enterprise');
                    case 'soho':
                        $(form).attr('action', vf.config.searchbox.searchTarget[('' === facets.businesstype ? 'enterprise' : facets.businesstype)]).attr('method', 'GET');
                        $('input[name="searchCustomerContext"]').val(('business' === facets.customertype ? ('' === facets.businesstype ? 'enterprise' : facets.businesstype) : facets.customertype));
                        break;
                    }
                    $('input[name="searchContext"]').val('domain.' + facets.domain);
                    break;
                case 'hierarchy':
                    var hierarchyKey = vf.config.searchbox.hierarchy[0],
                        contextSr = vf.config.areas.maps.hierarchy.searchCustomerContext[hierarchyKey];
                    $(form).attr('action', vf.config.searchbox.searchTarget[contextSr]).attr('method', 'GET');
                    $('input[name="searchCustomerContext"]').val(contextSr);
                    $('input[name="searchContext"]').val('domain.allg-info');
                    break;
                case 'path':
                    var contextSr = getResolvedByPath();
                    $(form).attr('action', vf.config.searchbox.searchTarget[contextSr]).attr('method', 'GET');
                    $('input[name="searchCustomerContext"]').val(contextSr);
                    $('input[name="searchContext"]').val('domain.allg-info');
                    break;
                }
                if ($(form).hasClass('search-box')) {
                    $('#asksearchContext').val('domain.support');
                }
                facetsStr = $(form).find('input[name="searchCustomerContext"]').val() + '|' + $(form).find('input[name="searchContext"]').val();
                $(form).find('input[name="searchCustomerContext"]').val(facetsStr);
                $(form).find('input[name="searchContext"]').remove();
            },
            removeNotNeededFields: function (form) {
                var form = form;
                $(form).find('input[type="hidden"]').each(function (f, e) {
                    if ('searchContext' !== $(e).attr('id') && 'asksearchContext' !== $(e).attr('id') && 'searchCustomerContext' !== $(e).attr('id') && 'asksearchCustomerContext' !== $(e).attr('id')) {
                        $(e).remove();
                    }

                });

            }
        };
        vf.setupSearchboxTracking = function (d, q) {
            $('.se-results ul li a').on('click', function (e) {
                _ddq.push(['autosuggest', { 'type': 'click', 'strSearchTerm': q, 'url': $(this).attr('href') }]);
                document.location.href = $(this).attr('href');
                return true;
            });
        };
    })(vf);

    //$('.se-input').attr('autocomplete', 'off').attr('name', 'q');
    // $('.se-input').attr('accept-charset', 'ISO-8859-1');
    $('body').on('click', function () {
        if ($('.se-results:visible')) {
            $('.se-results').hide();
        }
    });



    window.getCartCount = function () {
        var olsCart = parseInt($.cookie('OLSCARTCOUNT')),
            cart = parseInt($.cookie('CARTCOUNT'));
        return (false === (isNaN(olsCart))) ? olsCart : ((false === (isNaN(cart))) ? cart : 0);
    };
    window.getEntCartCount = function () {
        var eolsCart = parseInt($.cookie('EOLSCARTCOUNT'));
        return false === isNaN(eolsCart) ? eolsCart : 0;
    };

    window.buildCartCount = function () {
        $('.counter').text(getCartCount());
    };

    $.removeCookie = function (key, options) {
        // Must not alter options, thus extending a fresh object...
        $.cookie(key, '', $.extend({}, options, {
            expires: -1
        }));
        return !$.cookie(key);
    };
    $(buildCartCount);

    if (-1 !== document.location.href.indexOf('/firmenkunden') || -1 !== document.location.href.indexOf('/selbstaendige') || -1 !== document.location.href.indexOf('/business')) {
        if ((!($('div.nav-aux.row ul.fl>li:first').hasClass('nav-aux-current'))) && 3 === $('div.nav-aux.row ul.fl>li').length) {
            $('div.nav-aux.row ul.fl>li:last').addClass('displayNone');
        }

        if (!$.fn.exists('#headerSearch')) {
            $('#nav-search').find('form').append('<input type="hidden" value="business" name="header" id="headerSearch">');
        } else {
            $('#headerSearch').val('business');
        }
        if ((-1 !== document.location.href.indexOf('/business') || -1 !== document.location.href.indexOf('/selbstaendige')) && -1 === document.location.href.indexOf('/firmenkunden')) {
            $('#searchCustomerContext').val('soho');
        } else if (-1 !== document.location.href.indexOf('/firmenkunden')) {
            $('#searchCustomerContext').val('enterprise');
        }

    }

    /**chat functions for checkout and transmitting chat number  */

    window.cLogicGetOshopChannel = function () {
        var vfprefix = 'VFD2_';

        function getCookieValue() {
            return $.cookie('oshop');
        }

        function checkCookieValue(v) {
            v = v.replace('queryparams||vfchannel||', '');
            switch (parsInt(v)) {
            case 1003:
                return vfprefix + '33333811';
            case 1002:
                return vfprefix + '33333344';
            case 1001:
                return vfprefix + '33333809';
            default:
                return null;
            }
        }
        return checkCookieValue(getCookieValue());
    };
    window.setInqSaleId = function () {
        if ($.fn.exists('#chatIdHidden')) { //maybe Inq is included on the page later in the future this case matches
            try {
                $('#chatIdHidden').val(InqSaleMgr.getSaleID());
            } catch (e) { }
        }
        try {
            $.cookie('inqSaleId', InqSaleMgr.getSaleID(), {
                path: '/',
                domain: VF.cookieDomain,
                expires: 30
            });
            // but to ensure that we are able to transfer the information we set a cookie we this information
        } catch (e) { }
    };
    window.getInqSaleId = function () {
        if ($.fn.exists('#chatIdHidden') && $.cookie('inqSaleId')) {
            //now we are on the page which is main target to grab the data and we have a cookie with the information
            $('#chatIdHidden').val($.cookie('inqSaleId'));
            $.cookie('inqSaleId', null); // we write the value into the hidden field
        } else if ($.fn.exists('#chatIdHidden')) {
            var result = cLogicGetOshopChannel();
            if (null !== result) {
                $('#chatIdHidden').val(result);
            }
        }
    };
    window.sendChatEventToOmniture = function () {
        try {
            s.FlyoutClick('chat_engaged');
        } catch (e) { }
    };
    window.chatEngagedListener = {
        onChatEngagedEvent: function () {
            sendChatEventToOmniture('engage');
        }
    };
    window.InqRegistry = {
        saleListeners: [chatEngagedListener],
        listeners: [{
            onSaleQualifiedEvent: function () {
                setInqSaleId();
            }
        }]
    };

    $(function () {
        if (false === vf.search.statusActive) {
            vf.search.settings.target = '.search';
            vf.search.settings.results = '.se-results';
            vf.search.settings.input = '.exsg_input';
            vf.search.settings.serviceUrl = '/api/search/SearchBridge/search?sv=suggest&wt=json';

            vf.search.init('.search');
        }/*else if(1 === $('.serp-search').length){
            vf.search.settings.target = '.serp-search';
            vf.search.settings.input = '.search-string';
            vf.search.init($('.serp-search'));
        }*/
    });
}(window));
